.footer {
  position: relative;

  margin-top: 10px;
  bottom: 0;
  left: 0;
  /* Ajuste o valor de 'left' conforme necessário */
  background-color: #333;
  /* Cor de fundo do footer */
  color: #fff;
  /* Cor do texto do footer */
  padding: 10px;
  /* Espaçamento interno do footer */
  text-align: center;
  /* Alinhe o conteúdo ao centro */
}

a {
  /* font-size: large; */
  text-decoration: none;
}

/* .color1 { #f7dece };
.color2 { #eed7c5 };
.color3 { #ccccbb };
.color4 { #9ec4bb };
.color5 { #2d2e2c }; */