.containerSacola {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-sizing: border-box;
  letter-spacing: 0.05rem;
  outline: 0 none;
  margin: 0;
  padding: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.containerformSacola {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.formSelecCelula {
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: nowrap;
}


label {
  /* position: relative; */
  display: inline-block;
  margin: 20px 0 20px 0;
}

label:before {
  /* content: ""; */
  height: 31px;
  position: absolute;
  right: 7px;
  top: 3px;
  width: 22px;
  background: #fff;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  pointer-events: none;
  display: block;
}

label:after {
  /* content: " "; */
  position: absolute;
  right: 15px;
  top: 46%;
  margin-top: -3px;
  z-index: 2;
  pointer-events: none;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6.9px 4px 0 4px;
  border-color: #aaa transparent transparent transparent;
  pointer-events: none;
}

label select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  padding: 0 30px 0 10px;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  line-height: 36px;
  height: 36px;
  background: #fff;
  margin: 0 5px 5px 0;
}

select::-ms-expand {
  display: none;
}


table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
  margin-bottom: 20px;
}

table,
th,
td {
  border: 1px solid #ddd;
}

thead {
  background-color: #f2f2f2;
}

th,
td {
  padding: 10px;
  text-align: left;
}

/* Estilos para dispositivos móveis */
@media screen and (max-width: 800px) {
  table {
    border: 0;
  }

  table thead {
    display: none;
  }

  table tbody tr {
    margin-bottom: 20px;
    display: block;
    border: 1px solid #ddd;
  }

  table tbody td {
    display: block;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  table tbody td:last-child {
    border-bottom: 0;
  }
}