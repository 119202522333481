.headerclass {
  background-color: #333333;
  /* Fundo mais escuro */
  /* height: 100%; */
  color: #fff;
  /* Texto branco */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
}

.navClass {
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  /* Defina a cor de fundo como transparente */
  /* backdrop-filter: blur(2px); */
  /* Aplica um desfoque de 10px ao fundo */
  /* animation: slideInUp 0.2s ease-in-out;
  animation: slideInUp 0.2s ease-in-out;
  box-shadow: -12px 14px 6px rgb(0 0 0 / 22%); */
}

@keyframes slideInUp {
  from {
    transform: translateY(10%);
  }

  to {
    transform: translateY(0);
  }
}

.navClassContexto {
  background-color: #333333fd;
  border-bottom-left-radius: 10px;
  /* border-bottom-right-radius: 20px; */
  /* Fundo mais escuro */
  color: #fff;
  /* Texto branco */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 30%;
  right: -100%;
  /* Inicialmente, esconda o elemento fora da tela à direita */
  align-items: center;
  padding: 20px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.2s ease-in-out;
}

/* .navClassContexto:hover {
  animation: scaleUp 0.2s ease-in-out;
} */

@keyframes slideIn {
  from {
    transform: translateY(0%);
    transform: translateX(100%);
    /* Comece fora da tela à direita */
  }

  to {
    transform: translateX(0%);
    /* Mova para a posição inicial */
  }
}

/* @keyframes scaleUp {
  from {
    transform: scale(12.9);
  }

  to {
    transform: scale(1);
  }
} */

.logo {
  display: flex;
  align-items: flex-start;
  flex-direction: row;
  text-align: left;
}

.logo img {
  width: 65px;
  margin-right: 5px;
}

.logo h1 {
  text-align: left;
  font-size: 20px;
  color: #fff;
  padding: 0;
  margin: 0;
}

.logo h5 {
  text-align: left;
  padding: 0;
  margin: 0;
  font-size: 10px;
  color: #fff;
}

.nav-list {
  list-style: none;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  margin: 0;
  padding: 0;
}

.nav-item {
  font-size: 18px;
}

.nav-link {
  color: #fff;
  /* Texto branco */
  text-decoration: none;
  transition: color 0.3s ease;
}

.nav-link:hover {
  color: #ccc;
  /* Texto mais claro ao passar o mouse */

  /* Media query para telas menores */


}

#header {
  width: 100%;
  height: 60px;
  background-color: #333;
  position: fixed;
  top: 0;
  left: 0;
}

#hamburger-menu {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: relative;
}

#hamburger-menu::before,
#hamburger-menu::after {
  content: '';
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 30px;
  height: 5px;
  background-color: #fff;
  border-radius: 2px;
}

#hamburger-menu::before {
  top: 0;
}

#hamburger-menu::after {
  bottom: 0;
}

#hamburger-menu.is-active::before,
#hamburger-menu.is-active::after {
  transform: translate(-50%, -50%) rotate(45deg);
}

#hamburger-menu.is-active::after {
  transform: translate(-50%, -50%) rotate(-45deg);
}


.headerclass {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-list {
  display: flex;
  flex-direction: column
}



.headerclass nav ul li {
  font-size: 12px;
  display: flex;
  flex-direction: column;

}