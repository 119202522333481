.contextAssistenteForm {
  min-height: 633px;

  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
}

.containerformAssistenteForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.input-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  /* Espaçamento entre os elementos */
  /* width: 100%; */
  /* Define a largura total para o container */
}

.input-container h1 {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  overflow: hidden;
  color: #181a1b;
  /* Oculta o texto que não está visível inicialmente */
  white-space: nowrap;
  /* Evita quebras de linha */
  border-right: 2px solid transparent;
  /* Cria um "cursor" piscante */
  animation: typing 3s steps(20, end), blink-caret 0.5s step-end infinite;
}

/* Animação de digitação do texto */
@keyframes typing {
  from {
    width: 0;
    /* Começa com a largura do texto como zero */
  }

  to {
    width: 100%;
    /* Aumenta gradualmente a largura até 100% */
  }
}

/* Animação do cursor piscante */
@keyframes blink-caret {

  from,
  to {
    border-color: transparent;
    /* Cursor invisível */
  }

  50% {
    border-color: #000;
    /* Cursor visível */
  }
}

label {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 10px;
}

input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  outline: none;
  font-size: 16px;
}

button {
  margin: 20px 0 0 0;
  width: 50%;
  background-color: #007bff;

  color: #fff;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0069d9;

}

.noti {
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 999;
}

.noti.success {
  background-color: #41c96a;
}

.noti.error {
  background-color: #d9534f;
}

.noti .msg {
  font-size: 16px;
  font-weight: bold;
  padding: 10px;
  border-radius: 5px;
}