/* Estilos para o componente de cadastro de doadores */
.containerDoadorDoadorBodyPage {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.containerDoador-header {
  background-color: #333;
  color: #fff;
  padding: 10px;
  font-size: 18px;
}

.containerDoadorDoador {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 600px;
  padding: 20px;
  background-color: #f7f7f7;
  border: 1px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.containerDoadorDoador h1 {
  font-size: 24px;
  margin-bottom: 20px;
}

.containerDoador-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-collapse: collapse;
  width: fit-content;
}

.containerDoador-form label {
  font-weight: bold;
}

.containerDoador-form input[type="text"] {
  width: 95%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.containerDoador-form select {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.containerDoador-form button {
  margin: 5px;
  background-color: #00629a;
  color: #fff;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  /* margin-right: 10px; */
  width: 95%;
}

.containerDoador-form button:last-child {
  margin-right: 0;
}

.containerDoador-form button:hover {
  background-color: #0056b3;
}

.containerDoador-form ul {
  list-style-type: none;
  padding: 0;
}

.containerDoador-form ul li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

/* Estilo para remover sacolinha */
.containerDoador-form ul li button {
  background-color: #d9534f;
  color: #fff;
  padding: 5px 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;

}

.containerDoador-form ul li button:hover {
  background-color: #c9302c;
}